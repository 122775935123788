import { future as base } from '@theme-ui/presets';

const heroTextColor = '#444';

export default {
  ...base,
  colors: {
    ...base.colors,
    // background: "#ededf5",
    background: '#edf2f7',
  },
  text: {
    ...base.text,
    heroBig: {
      // textTransform: "uppercase",
      fontSize: 7,
      fontWeight: 'bold',
      color: heroTextColor,
    },
    heroSmall: {
      fontSize: 2,
      color: heroTextColor,
    },
  },
  styles: {
    ...base.styles,
  },
};
